import React from "react"
import { graphql } from "gatsby"
import { Container } from "react-bootstrap"

import Layout from "../../../components/layout"
import Slider from "../../../components/slider"

const Media = ({ data, location }) => {
  const posts = data.allVideosYaml.edges

  const title = "Videók"
  const subtitle = "Rövidfilmek az AURA Alapítványról"

  return (
    <Layout location={location} title={title} description={subtitle}>

      <Slider title={title} subtitle={subtitle}/>

      <Container>
        {posts.map(({ node }) => {
          return (
            <article>
              <header>
                <h4>
                  <a href={node.video.url}>{node.video.title}</a>
                </h4>
              </header>
              <section>
                <p>{node.video.description}</p>
                <small>
                  {node.video.date}, {node.video.author}
                </small>
              </section>
              <hr/>
            </article>
          )
        })}
      </Container>
    </Layout>
  )
}

export default Media

export const pageQuery = graphql`
  query {
    allVideosYaml(sort: { fields: [video___date], order: DESC }) {
      edges {
        node {
          video {
            author
            date
            description
            language
            title
            url
          }
        }
      }
    }
  }
`
